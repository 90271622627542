export enum RoleCode {
  SuperAdmin = 'superadmin',
  Admin = 'admin',
  Technician = 'technician',
  Salesman = 'salesman',
  Installer = 'installer',
  CustomerSuperAdmin = 'customersuperadmin',
  CustomerAdmin = 'customeradmin',
  ChainDirector = 'chaindirector',
  CinemaDirectorOperational = 'cinemadirectoroperational',
  CinemaDirectorReader = 'cinemadirectorreader',
}
